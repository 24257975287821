import { gql } from "@apollo/client";

export const SEARCH_PAPAS = gql`
  query PapasVisitList($filter: [PapaFilter], $pagination: PaginationInput, $sorting: PapaSorting) {
    papas(filter: $filter, pagination: $pagination, sorting: $sorting) {
      data {
        id
        fullName
        accountId
        businessId
        status
        virtualVisitsOnly
        dataVisibility {
          concealed
        }
        account {
          data {
            type
            fullName
          }
        }
      }
      pagination {
        limit
        totalCount
      }
    }
  }
`;

export const CREATE_VISIT = gql`
  mutation createVisit($input: VisitInput!) {
    createVisit(input: $input) {
      data {
        id
      }
    }
  }
`;

export const PAPA_QUERY = gql`
  query PapaQuery($papaId: ID!) {
    papa(id: $papaId) {
      data {
        fullName
        phoneNumber
        dataVisibility {
          concealed
        }
        account {
          data {
            plan {
              data {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CREDIT_CARD_QUERY = gql`
  query creditCard($id: ID!) {
    creditCard(id: $id) {
      data {
        last4
      }
    }
  }
`;

export const CREATE_VISIT_RECURRENCE = gql`
  mutation createScheduleVisitRecurrence($input: VisitRecurrenceInput!) {
    createVisitRecurrence(input: $input) {
      data {
        id
        visits {
          data {
            id
            recurrence {
              data {
                id
                firstScheduledFor
                validUntil
                config {
                  freq
                  interval
                  byDay
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_BY_PAPA = gql`
  query AccountByPapa($papaId: ID!) {
    papa(id: $papaId) {
      data {
        defaultToFavoritePalsOnly
        businessId
        caregiver {
          data {
            id
            type
          }
        }
        account {
          data {
            id
            type
          }
        }
      }
    }
  }
`;

export const PAPA_RESOURCE_BUDGET = gql`
  query papaResourceBudget($id: ID!, $givenDate: Datetime) {
    papa(id: $id) {
      data {
        resourceBudget(givenDate: $givenDate) {
          data {
            remainingMinutesOnDay
          }
        }
        account {
          data {
            id
            fullName
          }
        }
        caregiver {
          data {
            id
            business {
              data {
                id
                accountId
              }
            }
          }
        }
      }
    }
  }
`;

export const BUSINESS_POLICY_ON_DATE = gql`
  query businessPolicyOnDate($accountId: UUID!, $givenDate: Datetime) {
    businessPolicies(
      filter: {
        accountId: { eq: $accountId }
        startsAt: { lte: $givenDate }
        endsAt: { gte: $givenDate }
      }
    ) {
      data {
        allowInPersonVisits
        allowVirtualVisits
        maxMinutesPerVisit
        allowUberForTransportation
        endsAt
        inPersonAllowedTasks {
          data {
            id
            name
          }
        }
        virtualAllowedTasks {
          data {
            id
            name
          }
        }
      }
    }
  }
`;

export const LOCATION_LEAD_HOURS = gql`
  query locationLeadHours($locationId: ID!) {
    location(id: $locationId) {
      data {
        visitLeadHours
        earliestAllowedVvDate
        earliestAllowedIpvDate
        supportOperatingHours {
          startTime
          endTime
        }
      }
    }
  }
`;

export const LOCATION_PAL_SUPPLY = gql`
  query locationPalSupply($locationId: ID!) {
    locationPalSupply(locationId: $locationId) {
      sufficientSupply
    }
  }
`;

export const SYSTEM_NOTES_QUERY = gql`
  query AdminSystemNotesForVisit($input: VisitInput!) {
    systemNotesForVisit(input: $input) {
      data
    }
  }
`;
