import { useApolloClient, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import debounce from "lodash/debounce";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { FormikValueObserver } from "components/FormikValueObserver";
import config from "config";
import { DEFAULT_TIMEZONE } from "constants/date";
import { VisitSingleResult, useLocationLeadHoursLazyQuery } from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import { setDeliveryBasedOnTasks } from "pages/ScheduleVisit/shared/DeliveryVisitHelpers";
import * as Segment from "utils/segment";
import { isOvernightTime } from "utils/time";

import HourAllotmentReached from "../HourAllotmentReachedModal";
import { NoObjectiveConfirmModal } from "../NoObjectiveConfirmModal";
import OvernightModals from "../OvernightModals";
import PapaCovidVaccinationModal from "../PapaCovidVaccinationModal";
import { VisitSteps } from "../Steps";
import {
  VisitErrorMessage,
  getTasksWithNoObjectives,
  isByDayRecurrence,
  mapSubmitFormValues,
  maxScheduledVisitsPerDayReachedErrorMessage,
  operatingHoursErrorMessage,
} from "../constants";
import { CREATE_VISIT, CREATE_VISIT_RECURRENCE, SYSTEM_NOTES_QUERY } from "../gql";
import { visitSchema } from "../schema";
import { Values } from "../types";

interface Props {
  initialValues: Values;
  isCloneMode?: boolean;
}

const CreateVisit = ({ initialValues, isCloneMode }: Props) => {
  const client = useApolloClient();

  const [createVisit, { loading: createVisitLoading }] = useMutation<{
    createVisit?: VisitSingleResult | null;
  }>(CREATE_VISIT);

  const [createVisitRecurrence, { loading: createVisitRecurrenceLoading }] =
    useMutation(CREATE_VISIT_RECURRENCE);
  const history = useHistory();

  const [visitFormValues, setVisitFormValues] = useState<Values>(initialValues);
  const [earliestAllowedDates, setEarliestAllowedDates] = useState<{
    VvDate: string;
    IpvDate: string;
  }>();
  const [taskIdsWithNoObjectives, setTaskIdsWithNoObjectives] = useState<string[]>([]);
  const { isOpen: hourAllotmentReachedModalOpen, toggle: toggleHourAllotmentReachedModal } =
    useModalToggle();

  const { isOpen: papaCovidVaccinationModalOpen, toggle: togglePapaCovidVaccinationModal } =
    useModalToggle();

  const { isOpen: noObjectiveConfirmModalOpen, toggle: toggleNoObjectiveConfirmModal } =
    useModalToggle();

  const {
    isOpen: overnightTransportationVisitModalOpen,
    toggle: toggleOvernightTransportationVisitModal,
  } = useModalToggle();

  const {
    isOpen: noNonTransportationOvernightVisitModalOpen,
    toggle: toggleNoNonTransportationOvernightVisitModal,
  } = useModalToggle();

  const { isOpen: demandProfilesLeadTimeModalOpen, toggle: toggleDemandProfilesLeadTimeModal } =
    useModalToggle();

  const [getLocationLeadHours] = useLocationLeadHoursLazyQuery();

  const handleLocationChange = async (locationId: string) => {
    const { data } = await getLocationLeadHours({ variables: { locationId: locationId } });
    const earliestAllowedVvDate = data?.location?.data?.earliestAllowedVvDate;
    const earliestAllowedIpvDate = data?.location?.data?.earliestAllowedIpvDate;

    setEarliestAllowedDates({ VvDate: earliestAllowedVvDate, IpvDate: earliestAllowedIpvDate });
  };

  const checkForNoObjectives = async (values: Values) => {
    setDeliveryBasedOnTasks(values);

    setVisitFormValues(values);
    const taskIds = getTasksWithNoObjectives(values);

    if (taskIds.length > 0) {
      setTaskIdsWithNoObjectives(taskIds);
      toggleNoObjectiveConfirmModal();
    } else {
      await checkForOvernightVisits(values);
    }
  };

  const checkForOvernightVisits = async (values: Values) => {
    const selectedDate = Boolean(values.selectedRecommendedDate)
      ? values.selectedRecommendedDate
      : values.scheduledForDay;

    if (isOvernightTime(selectedDate, values.scheduledForTime, values.estimatedDuration)) {
      values.hasOvernightTransportationTasks
        ? toggleOvernightTransportationVisitModal()
        : toggleNoNonTransportationOvernightVisitModal();
    } else {
      await checkIfWithin72HoursAndUberIneligible(values);
    }
  };

  const checkIfWithin72HoursAndUberIneligible = async (values: Values) => {
    const locationId = values.location?.id;
    if (!locationId) return;

    const selectedDate = Boolean(values.selectedRecommendedDate)
      ? values.selectedRecommendedDate
      : values.scheduledForDay;

    const earliestAllowedDate =
      values?.isVirtual === true ? earliestAllowedDates?.VvDate : earliestAllowedDates?.IpvDate;

    if (moment(selectedDate).diff(moment(earliestAllowedDate), "hours") < 0) {
      toggleDemandProfilesLeadTimeModal();
    } else {
      await handleSubmit(values);
    }
  };

  const getSystemNotesForVisit = async (values: Values) => {
    try {
      const input = mapSubmitFormValues(values);
      const { data } = await client.query({
        query: SYSTEM_NOTES_QUERY,
        variables: { input: input },
      });

      if (data?.systemNotesForVisit.data.length) {
        return data.systemNotesForVisit.data;
      }
    } catch (error) {
      return [];
    }
  };

  const debouncedHandleValuesChange = useRef(
    debounce(async (values: Values, initialValues: Values) => {
      const systemNotes = await getSystemNotesForVisit(values);
      values.systemNotes = systemNotes;
      setVisitFormValues(values);
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleValuesChange.cancel();
    };
  }, [debouncedHandleValuesChange]);

  const handleSubmit = async (values: Values) => {
    const input = mapSubmitFormValues(values);

    const { freq, byDay: byDayValue, count, endOnDate } = values;
    const validUntil =
      values.validUntil && moment(values.validUntil).isValid()
        ? moment.tz(
            moment(values.validUntil, "YYYY-MM-DD").format("YYYY-MM-DD"),
            values.location?.timezone || DEFAULT_TIMEZONE
          )
        : null;

    try {
      const { data } = await createVisit({ variables: { input } });
      if (data?.createVisit?.data?.id) {
        const { id: visitId } = data.createVisit.data;

        if (freq) {
          const byDay = isByDayRecurrence(freq) ? byDayValue : null;
          await createVisitRecurrence({
            variables: {
              input: {
                freq,
                byDay,
                templateVisitId: visitId,
                validUntil: endOnDate ? validUntil?.toISOString() : null,
                count: endOnDate ? null : count,
              },
            },
          });
          toast.success("Your recurrent visit was created with success!");
        } else {
          toast.success("Your visit was created with success!");
        }

        history.push(`/visits/${visitId}`);
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      if (
        config.featureFlag.businessPolicy &&
        (error as Error).message === VisitErrorMessage.hourAllotmentReached
      ) {
        toggleHourAllotmentReachedModal();
      } else if (
        config.featureFlag.businessPolicy &&
        (error as Error).message === VisitErrorMessage.papaCovidVaccination
      ) {
        togglePapaCovidVaccinationModal();
      } else if ((error as Error).message === VisitErrorMessage.scheduledOutsideBusinessHours) {
        toast.error(operatingHoursErrorMessage);
      } else if (
        config.featureFlag.businessPolicy &&
        (error as Error).message === VisitErrorMessage.max_scheduled_visits_per_day_reached
      ) {
        toast.error(maxScheduledVisitsPerDayReachedErrorMessage);
      } else {
        window.console.error(error);
        toast.error((error as Error).message);
      }
    }
  };

  const beginSubmit = async (values: Values) => {
    Segment.visitSchedulingAction("save visit", "clicked submit visit", null, values.papaId);
    checkForNoObjectives(values);
  };

  /*
   * To exclude fields from form submission append the field name to the list
   * found in the body of `omitExtraFields` in the source file -
   * ./src/pages/ScheduleVisit/constants.ts
   */
  return (
    <>
      <Formik<Values>
        initialValues={initialValues}
        validationSchema={visitSchema}
        onSubmit={beginSubmit}
      >
        {({ submitForm }) => (
          <Form>
            <FormikValueObserver onChange={debouncedHandleValuesChange} />
            <VisitSteps
              isDemandProfilesLeadTimeModalOpen={demandProfilesLeadTimeModalOpen}
              toggleDemandProfilesLeadTimeModal={toggleDemandProfilesLeadTimeModal}
              onDemandProfilesLeadTimeModal={handleSubmit}
              visitFormValues={visitFormValues}
              handleLocationChange={handleLocationChange}
              earliestAllowedDates={earliestAllowedDates}
              isCloneMode={isCloneMode}
            />

            <HourAllotmentReached
              isOpen={hourAllotmentReachedModalOpen}
              toggle={toggleHourAllotmentReachedModal}
            />

            <PapaCovidVaccinationModal
              isOpen={papaCovidVaccinationModalOpen}
              toggle={togglePapaCovidVaccinationModal}
              onProceed={submitForm}
            />
            <OvernightModals
              noTransportationOpen={noNonTransportationOvernightVisitModalOpen}
              toggleNoTransportation={toggleNoNonTransportationOvernightVisitModal}
              transportationOpen={overnightTransportationVisitModalOpen}
              toggleTransportation={toggleOvernightTransportationVisitModal}
              visitFormValues={visitFormValues}
              onSubmit={checkIfWithin72HoursAndUberIneligible}
            />
          </Form>
        )}
      </Formik>
      <NoObjectiveConfirmModal
        isOpen={noObjectiveConfirmModalOpen}
        loading={createVisitLoading || createVisitRecurrenceLoading}
        visitFormValues={visitFormValues}
        taskIdsWithNoObjectives={taskIdsWithNoObjectives}
        onSubmit={checkForOvernightVisits}
        toggle={toggleNoObjectiveConfirmModal}
      />
    </>
  );
};

export default CreateVisit;
